import image_1 from "../images/showcase/practical/glitch_garden.png";
import image_2 from "../images/showcase/practical/platformer.png";
import image_3 from "../images/showcase/practical/ball.png";
import image_4 from "../images/showcase/practical/laser_defender.png";
import image_5 from "../images/showcase/practical/fps.png";
import image_6 from "../images/showcase/practical/rocket.png";
import image_7 from "../images/showcase/practical/space_shooter.png";

function getGamePracticalData() {
  const GAME_PRACTICAL_PROJECTS = [
    {
      title: "The Glitch Garden",
      url: "https://patcode.co.uk/video/glitch_garden.mp4",
      image: image_1,
      shortDescription: "Plants vs Zombie like game (Unity Engine & C#, 2019)",
      description: "N/A",
    },
    {
      title: "Platformer 2D",
      url: "https://patcode.co.uk/video/platformer_2d.mp4",
      image: image_2,
      shortDescription: "2D platformer (Unity Engine & C#, 2019)",
      description: " - use of tile tool \n - dynamic camera",
    },
    {
      title: "Block Crusher",
      url: "https://patcode.co.uk/video/block_crusher.mp4",
      image: image_3,
      shortDescription: "Arkanoid like game (Unity Engine & C#, 2019)",
      description: "N/A",
    },
    {
      title: "Laser Defender",
      url: "https://patcode.co.uk/video/laser_defender.mp4",
      image: image_4,
      shortDescription: "Galaxy like game (Unity Engine & C#, 2019)",
      description: "N/A",
    },
    {
      title: "FPS game",
      url: "https://patcode.co.uk/video/fps.mp4",
      image: image_5,
      shortDescription: "First Person Shooter (Unreal Engine & C++, 2020)",
      description: "- implementation of AI (behaviour tree)",
    },
    {
      title: "Rocket Balance",
      url: "https://patcode.co.uk/video/rocket_balance.mp4",
      image: image_6,
      shortDescription: "Simple 3D balance game (Unity Engine & C#, 2020)",
      description: "- physics practice",
    },
    {
      title: "Space Shooter",
      url: "https://patcode.co.uk/video/space_shooter.mp4",
      image: image_7,
      shortDescription: "3D space shooter (Unity Engine & C#, 2020)",
      description: "- physics practice",
    },
  ];

  return GAME_PRACTICAL_PROJECTS;
}

export default getGamePracticalData;
