import image_1 from "../images/showcase/oldschool/pong.png";
import image_2 from "../images/showcase/oldschool/space_attackers.png";
import image_3 from "../images/showcase/oldschool/platformer_3d.png";
import image_4 from "../images/showcase/oldschool/strategy.png";
import image_5 from "../images/showcase/oldschool/toad.png";
import image_6 from "../images/showcase/oldschool/flight.png";
import image_7 from "../images/showcase/oldschool/prototype.png";

function getOldschoolProjectsData() {
  const UNIVERSITY_PROJECTS = [
    {
      title: "PONG",
      url: "https://patcode.co.uk/video/pong.mp4",
      image: image_1,
      shortDescription: "Pong game (Unity Engine & C#, 2018)",
      description: "N/A",
    },
    {
      title: "Galaxy",
      url: "https://patcode.co.uk/video/galaxy.mp4",
      image: image_2,
      shortDescription: "Galaxy like game (Unity Engine & C#, 2018)",
      description: "N/A",
    },
    {
      title: "Platformer 3D",
      url: "https://patcode.co.uk/video/platformer_3d.mp4",
      image: image_3,
      shortDescription: "3D platformer (Unity Engine & C#, 2018)",
      description: "N/A",
    },
    {
      title: "Strategy game",
      url: "https://patcode.co.uk/video/strategy.mp4",
      image: image_4,
      shortDescription: "Strategy game (Unity Engine & C#, 2018)",
      description: "N/A",
    },
    {
      title: "Toad game",
      url: "https://patcode.co.uk/video/toad.mp4",
      image: image_5,
      shortDescription: "Simple toad game (Unity Engine & C#, 2018)",
      description: "N/A",
    },
    {
      title: "Flight game",
      url: "https://patcode.co.uk/video/flight.mp4",
      image: image_6,
      shortDescription: "Flight game (Unity Engine & C#, 2018)",
      description: "N/A",
    },
    {
      title: "Mobile prototype",
      url: "https://patcode.co.uk/video/mobile_prototype.mp4",
      image: image_7,
      shortDescription: "Just a prototype (Unity Engine & C#, 2018)",
      description: "N/A",
    },
    {
      title: "Cinemachine",
      url: "https://patcode.co.uk/video/cinemachine.mp4",
      image: "image_4",
      shortDescription: "Cinemachine practice (Unity Engine & C#, 2018)",
      description: "N/A",
    },
  ];

  return UNIVERSITY_PROJECTS;
}

export default getOldschoolProjectsData;
